import './App.css';

function App() {
  return (
    <div className="App">
            <main className="App-main">
                <section id="content" className="App-content">
                    <div className="iframe-container">
                    <iframe title="Embedded Content" aria-label='FWARIC Charitable Foundation' frameborder="0" allowFullScreen src='https://forms.zohopublic.in/masterramesh96/form/FWARIC/formperma/ct05skrzl3EWrWHwJ6oB9msxMw28qrL9xP8cpx23ifI'></iframe>
              
                    </div>
                </section>
            </main>
            <footer className="App-footer">
                <p className='App-footer-text'>&copy; 2024 FWARIC Charitable Foundation</p>
            </footer>
        </div>
  );
}

export default App;
